import(/* webpackMode: "eager" */ "/codebuild/output/src3778769675/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3778769675/src/src/assets/images/banner/banner-app-store.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3778769675/src/src/assets/images/banner/banner-google-play.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3778769675/src/src/components/layouts/CommonFooter/CommonFooter.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3778769675/src/src/components/layouts/CommonHeader/CommonHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3778769675/src/src/components/layouts/CommonMenu/CommonMenu.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3778769675/src/src/styles/modules/utils.module.scss");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3778769675/src/src/styles/modules/layout.module.scss");
